import Map from 'ol/Map.js';
import OSM from 'ol/source/OSM.js';
import Static from 'ol/source/ImageStatic.js';
import View from 'ol/View.js';
import proj4 from 'proj4';
import {Image as ImageLayer, Tile as TileLayer} from 'ol/layer.js';
import {getCenter} from 'ol/extent.js';
import {register} from 'ol/proj/proj4.js';
import {transform, useGeographic, addProjection, get as getProjection} from 'ol/proj.js';
import ZoomSlider from 'ol/control/ZoomSlider.js';

// Define the proj4 string for your custom projection
const proj4String = '+proj=stere +lat_0=56 +lon_0=40.5 +ellps=bessel';

// Register the projection with proj4
proj4.defs('EPSG:1234', proj4String);
register(proj4); // Register proj4 with OpenLayers

const imageExtent = [-1600000, -1600000, 1600000, 1600000];

// Create and add the custom projection to OpenLayers
//const customProjection = getProjection('EPSG:1234');
//customProjection.setExtent(imageExtent); // Define the extent
//addProjection(customProjection);

const imageLayer = new ImageLayer({opacity: 0.7});
useGeographic();

const map = new Map({
  layers: [
    new TileLayer({
      source: new OSM(),
    }),
    imageLayer,
  ],
  target: 'map',
  view: new View({
    center: [37, 55],
    zoom: 4,
  }),
});

map.addControl(new ZoomSlider());

(async () => {
    try {
        const response = await fetch('/demo/viz/tiles.json');
        const data = await response.json();

        var tiles=[];
        Object.entries(data).forEach(([timestamp, path]) => {
        	tiles.push({
            	label: timestamp.slice(-5),
            	timestamp: new Date (timestamp),
            	path: path,
	        source : new Static({
		    url: '/demo/viz/'+path,
		    crossOrigin: '',
		    projection: 'EPSG:1234',
		    imageExtent: imageExtent,
		    interpolate: false,
		  })
        	});
        });


      var startDate = tiles[0].timestamp;
      var tile_num=0;
      var frameRate = 2; // frames per second
      var animationId = null;

      function updateInfo() {
        var el = document.getElementById('info');
        el.innerHTML = startDate.toISOString();
      }

      function setTime() {
        tile_num++;
        if (tile_num > tiles.length-1) tile_num=0;
        startDate=tiles[tile_num].timestamp;

	imageLayer.setSource(tiles[tile_num].source);

        updateInfo();
      }
      setTime();

      var stop = function() {
        if (animationId !== null) {
          window.clearInterval(animationId);
          animationId = null;
        }
      };

      var play = function() {
        stop();
        animationId = window.setInterval(setTime, 1000 / frameRate);
      };

      var startButton = document.getElementById('play');
      startButton.addEventListener('click', play, false);

      var stopButton = document.getElementById('pause');
      stopButton.addEventListener('click', stop, false);

      updateInfo();
      play();
 
    } catch (error) {
        console.error('Error fetching data:', error);
    }
})();


